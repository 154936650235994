import React from "react"; // import thumbsImg from "../../images/positive-vote.png";
// import checkImg from "../../images/check-mark.png";
// import cartImg from "../../images/shopping-cart.png";

import homeImg from "../../images/home.png";
import "./scss/index.scss";

var CompanyFeature = function CompanyFeature(_ref) {
  var title = _ref.title;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "company-feature"
  }, React.createElement("div", {
    className: "container company-feature-container"
  }, React.createElement("div", {
    className: "feature4"
  }, React.createElement("img", {
    src: homeImg,
    alt: "Home"
  }), React.createElement("p", null, "Delivered To Your Door"), React.createElement("div", {
    className: "tableContainer",
    style: {
      marginTop: "-35px"
    }
  }, React.createElement("table", {
    className: "table"
  }, React.createElement("tr", null, React.createElement("th", null, "Order Amount"), React.createElement("th", null, "Radius in Km"), React.createElement("th", null, "Delivery Charge")), React.createElement("tr", null, React.createElement("td", null, "A$75"), React.createElement("td", null, "5"), React.createElement("td", null, "Free")), React.createElement("tr", null, React.createElement("td", null, "A$100"), React.createElement("td", null, "5-10"), React.createElement("td", null, "Free")), React.createElement("tr", null, React.createElement("td", null, "A$150"), React.createElement("td", null, "10-20"), React.createElement("td", null, "Free")), React.createElement("tr", null, React.createElement("td", null, "A$200"), React.createElement("td", null, "20-40"), React.createElement("td", null, "Free"))))))));
};

export default CompanyFeature;