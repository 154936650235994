import React from "react";
import meatStandard from "../../images/meatStandard.png";
import meatStandard1 from "../../images/standardImg1.png";
import "./scss/index.scss";

var MeatStandard = function MeatStandard(_ref) {
  var title = _ref.title;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "standardImage"
  }, React.createElement("img", {
    src: meatStandard,
    alt: "meat Standard"
  }), React.createElement("img", {
    src: meatStandard1,
    alt: "meat Standard"
  }))));
};

export default MeatStandard;