import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 225px;\n  height: 225px;\n  //max-width: 100%;\n  object-fit: cover;\n\n  > img {\n    width: 100%;\n    height: 225px;\n    //height: auto;\n    //max-width: 100%;\n    object-fit: cover;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  font-weight: normal;\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    padding: 1.8rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background: ", ";\n  padding: 2.5rem;\n  text-align: center;\n  max-height: 30rem;\n  transition: 0.3s;\n\n  :hover {\n    background-color: ", ";\n  }\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0 0 0.5rem 0;\n  text-align: left;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
import { css } from "styled-components";
var textProps = css(_templateObject(), function (props) {
  return props.theme.typography.baseFontSize;
});
export var Wrapper = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.colors.hoverLightBackground;
}, media.largeScreen(_templateObject3()));
export var Title = styled.h4(_templateObject4(), textProps);
export var Price = styled.p(_templateObject5(), textProps);
export var Image = styled.div(_templateObject6());