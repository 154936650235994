import * as React from "react";
import { RichTextContent } from "@components/atoms";
import { Breadcrumbs } from "../../components";
export var Page = function Page(_ref) {
  var breadcrumbs = _ref.breadcrumbs,
      headerImage = _ref.headerImage,
      navigation = _ref.navigation,
      page = _ref.page;
  return React.createElement("div", {
    className: "article-page"
  }, React.createElement("div", {
    className: "article-page__header",
    style: headerImage ? {
      backgroundImage: "url(".concat(headerImage, ")")
    } : null
  }, React.createElement("span", {
    className: "article-page__header__title"
  }, React.createElement("h1", null, page.title))), React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: breadcrumbs
  }), React.createElement("div", {
    className: "container",
    style: {
      margin: "70px 0px",
      textAlign: "justify"
    }
  }, window.location.pathname === "/page/contact-us/" ? React.createElement("div", {
    className: "contactInfo"
  }, React.createElement("div", null, React.createElement("iframe", {
    title: "google maps",
    style: {
      width: "100%",
      height: "620px"
    },
    src: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13266.381400725384!2d150.910714!3d-33.771121!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x72e071a3f8cc10f0!2sBlacktown%20Quality%20Meats!5e0!3m2!1sen!2snp!4v1630429748724!5m2!1sen!2snp"
  })), React.createElement("div", null, React.createElement("iframe", {
    title: "dib form",
    style: {
      width: "100%",
      height: "620px"
    },
    src: "https://demo.dibform.com/dynamic-form/ec86b52c-c20f-47de-8588-a0bee997bc5d"
  }))) : React.createElement(RichTextContent, {
    descriptionJson: page.contentJson
  }))));
};
export default Page;